import { useState, useContext, createContext, useEffect } from "react";
//import p1 from "../permits/JT11579.json"
import { access, getAllPaths, findProp } from "../tools/Nested";
import {multiPolygon, polygon,point, booleanPointInPolygon} from "@turf/turf";
import { Polygon, FeatureCollection } from 'geojson'
import { useSettings } from "./settingsContext";

export type PermitContextT = {
    setActivePermit: (o:number)=>void,
    activePermit:  number,
    permitFiles: JsonFile[]
    getNrRegulations: () => number
    getActivePermitData: () => any,
    returnAffectedPermits: (pos:any,datetime:any) => any[]
    getAllZones: ()=>any,
    checkTiming: (permit:any, datetime:string) => boolean
  }
export const PermitContext = createContext<PermitContextT>({
    setActivePermit: (n:number)=>{},
    activePermit: 0,
    permitFiles: [],
    getNrRegulations: () => 0,
    getActivePermitData: () => {},
    returnAffectedPermits: (pos:any, datetime:any) => [],
    getAllZones: ()=>{},
    checkTiming: (permit:any, datetime:string) => false
});

export interface JsonFile {
  fileName: string;
  fileData: any;
}
export const PermitProvider = (props:any)=> {
  const [activePermit, setActivePermit] = useState<number>(0)
  const [permitFiles, setPermitFiles] = useState<JsonFile[]>([]);
  const {currentSettings} = useSettings()

  useEffect(()=>{
    const fileContext = require.context('../permits/', false, /\.json$/)
    const keys = fileContext.keys();
    const files = keys.map(key => {
      const fileName = key.replace('./', '').replace('.json', '');
      const fileData = fileContext(key);
      return { fileName, fileData };
    });
    setPermitFiles(files);
    setActivePermit(0)
},[])

const getNrRegulations = () =>{
  return permitFiles[activePermit].fileData.trafficRegulationTrafficRegulationPublication.trafficRegulationsFromCompetentAuthorities.trafficRegulationOrder[0].trafficRegulation.length;
}

const getActivePermitData = () =>{
  return permitFiles[activePermit].fileData.trafficRegulationTrafficRegulationPublication.trafficRegulationsFromCompetentAuthorities.trafficRegulationOrder[0].trafficRegulation;
}

const convertGmlToTurfPolygon=(gmlPolygon: any)=>{
  let coordList: number[][][]=[];
  gmlPolygon.gmlPolygon.forEach((polygon:any)=>{
    let posList = polygon.exterior.posList.split(" ");
    let coords: number[][]=[];
    for (let i=0;i<posList.length;i += 2){
      //needs lat long, so we invert here
      coords.push([parseFloat(posList[i+1]), parseFloat(posList[i])])
    }
    //close it
    coords.push(coords[0])
    coordList.push(coords)
  })
  

  return multiPolygon([coordList],{"name":gmlPolygon.gmlAreaName.values[0].value});
}

const checkTiming = (permit:any,datetime:string) =>{
  let permitData = access(permit,permitFiles);
  let startD = new Date(findProp(permitData,"overallStartTime")[0]);
  let endD = new Date(findProp(permitData, "overallEndTime")[0]);
  let curD = new Date(datetime);
  let withinOverallPeriod = (startD < curD && curD < endD) 
  //reocurring daily periods
  let startTimesPath = getAllPaths(permitData, 'startTimeOfPeriod')
  let endTimesPath = getAllPaths(permitData, 'endTimeOfPeriod') 
  let negate = findProp(permitData,'negate')[0]
  let fullfillingReoccurring = true
  if (startTimesPath.length>0){  
    let allPeriodsCheck = startTimesPath.map((startTime, index)=>{
      let startT = access(startTime,permitData);
      let startDT = new Date(curD.toISOString().split("T")[0]+"T"+startT+":00.000Z");
      let endT = access(endTimesPath[index], permitData);
      let endDT = new Date(curD.toISOString().split("T")[0]+"T"+endT+":00.000Z");
      return startDT<curD && curD<endDT
    })
    if (negate && allPeriodsCheck.includes(true)) fullfillingReoccurring=false
    else if (!negate && !allPeriodsCheck.includes(true)) fullfillingReoccurring=false
  } 
  return withinOverallPeriod && fullfillingReoccurring
}

const getGrossVehicleWeight = () =>{
  let gvw = 0
  Object.values(currentSettings.vehicle).forEach((item:any)=>{
    gvw += item.value
  })
  return gvw
}

const checkWeight = (permit:any)=>{
  let permitData = access(permit,permitFiles)
  let data = access(getAllPaths(permitData, "trafficRegulationGrossWeightLimit")[0],permitData)
  if (data!==null && JSON.stringify(data) !== JSON.stringify({}) && data.hasOwnProperty("maxValue")){
      if ( getGrossVehicleWeight() <= data.maxValue.numericValue){
        return true 
      }
  }
  return false
}
const returnAffectedPermits = (pos:any, datetime:any):any[] =>{
  let affectedPermits: any[] = []
  let zones = getAllPaths(permitFiles,'gmlMultiPolygon')  
  let affectedRegulations: any[]=[]
  let checkPoint = point([pos["lon"],pos["lat"]])
  zones.forEach((zone)=>{
    let timeingOK = checkTiming(zone.split(".controlledZoneControlledZoneRegulation.")[0],datetime)
    let gmlData = access(zone, permitFiles)
    let zonePolygon = convertGmlToTurfPolygon(gmlData)
    let inside = booleanPointInPolygon(checkPoint, zonePolygon) 
    let weightOK = checkWeight(zone.split(".controlledZoneControlledZoneRegulation.")[0])
    if (inside && timeingOK){
      affectedRegulations.push(access(zone.split(".controlledZoneControlledZoneRegulation")[0],permitFiles))
    }
  })

  return affectedRegulations
}


const getAllZones=() =>{
  let zones = getAllPaths(permitFiles,'gmlMultiPolygon')  
  let zoneList = zones.map((zone)=>{
    let gmlData = access(zone, permitFiles)
    return gmlData
  })
  return {paths:zones.map(o=>{return o.split(".controlledZoneControlledZoneRegulation")[0]}),polygon:zoneList}
}

  const contextValue = {
    setActivePermit,
    activePermit,
    permitFiles,
    getNrRegulations,
    getActivePermitData,
    returnAffectedPermits,
    getAllZones,
    checkTiming,
  };
  
  return (
    <PermitContext.Provider value={contextValue}>
      {props.children}
    </PermitContext.Provider>
  );
}
export function usePermit() {
    return useContext(PermitContext);
  }