import { useState, useContext, useEffect,useRef, createContext} from "react";
import Geohash from "../tools/Geohash";
import { useSettings } from "./settingsContext";
//import flexpolyline from '@liberty-rider/flexpolyline';
import H from '@here/maps-api-for-javascript';
import { usePosition } from "./positionContext";


//export const HereContext = createContext(null);
export type HereContextT = {
  getMap: (lat?:number,lon?:number,zoom?:number)=>Promise<string>,
  getZoneOnMap:(zoneDef:any)=>Promise<string>,
  getRoute: (src:any,dest:any)=>Promise<any>,
  getSimData: (p:string)=>string,
  route: object,
}
export const HereContext = createContext<HereContextT>({
  getMap: async ()=>"",
  getZoneOnMap: async ()=>"",
  getRoute: async ()=>{},
  getSimData: ()=>"",
  route: {}
});

export function HereProvider(props:any) {
    const {currentSettings} = useSettings();
    const {updateSimList} = usePosition();
    const [inputParamMap,setInputParamMap] = useState({})
    const apiKey = process.env.REACT_APP_HERE_API_KEY;
    const [mapCache, setMapCache]=useState("")
    const [routeCache, setRouteCache]=useState({src:"",dst:""})
    const [route,setRoute]= useState({})
    const [routeLine,setRouteLine]= useState("")

    useEffect(()=>{
      let src = Geohash.decode(currentSettings.location.simStart.value)
      let dst = Geohash.decode(currentSettings.location.simEnd.value)
      getRoute(src,dst)

    },[currentSettings.location.simEnd.value,currentSettings.location.simStart.value])

    const getRoute=async(src,dest)=>{   
      let inputParamRoute = {src:src, dst:dest};
      let url = `https://router.hereapi.com/v8/routes?transportMode=truck&origin=${src.lat},${src.lon}&destination=${dest.lat},${dest.lon}&return=polyline,actions,instructions,turnbyturnactions,summary&spans=maxSpeed,segmentId,length&apiKey=${process.env.REACT_APP_HERE_API_KEY}`;
      console.log(`Getting route: ${url}`)
      if (JSON.stringify(route) !== JSON.stringify({}) && JSON.stringify( routeCache ) === JSON.stringify( inputParamRoute )){
        //there needs to be a route in cache and the parameters the same as now
        console.log("Using cached route") 
        return route;
      } else{
        let output = {};
        await fetch(url)
          .then(response => response.json())
          .then(incomingData => output = incomingData)
          .catch((err) => {
              console.log(err.message);  
          });
        setRoute(output)
        setRouteCache({src:src, dst:dest})

        //return output;
      }
    }
    useEffect(()=>{
      if  (JSON.stringify(route) !== JSON.stringify({})) {
         createSimulationDataList() 
      }
    },[route])

    const getMap=async (lat=52.53086,lon=13.38662,zoom=12)=>{
      let inputParamNew = {lat:lat, lon:lon, zoom:zoom};
      if (JSON.stringify(inputParamMap) !== JSON.stringify({}) && JSON.stringify( inputParamMap ) === JSON.stringify( inputParamNew )){
        console.log("Using cached map")
        return mapCache;
      } else{
        setInputParamMap({lat,lon,zoom})
        let output = "";
        let url = `https://image.maps.hereapi.com/mia/v3/base/mc/overlay:zoom=${zoom};padding=64/600x300/png8?apiKey=${apiKey}&style=${currentSettings.map.style.value}&scaleBar=km&geojson={"type":"FeatureCollection","features":[{"type":"Feature","properties":{"style":"circle","width":"60m","color":"%23EA4335","outline-color":"%23A60D0D","outline-width":"1px"},"geometry":{"coordinates":[[${lon},${lat}]],"type":"MultiPoint"}}]}`
        console.log(`Getting map: ${url}`)
        await fetch(url)
          .then(response => response.blob())
          .then(data => output=URL.createObjectURL(data) )
          .catch((err) => {
              console.log(err.message);            
          });
        setMapCache(output);
        
        return output;
        
      }      
    }

    const createSimulationDataList=()=>{
      if (route !== ""){
        let sections = route["routes"][0].sections
        const lineStrings: H.geo.LineString[] = [];
        let simPoints: number[][] = [];
        let points: number[] = [];
        sections.forEach((section) => {
            // convert Flexible Polyline encoded string to geometry
            //lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section[ "polyline" ]));
            //points = H.geo.LineString.fromFlexiblePolyline(section[ "polyline" ]).getLatLngAltArray()
            let myLineString = H.geo.LineString.fromFlexiblePolyline(section[ "polyline" ]); 
            let prevPoint: number[] = []            
            function eachFn(lat, lng, alt, idx) {
              if (prevPoint.length>0){
                  let d = getDistanceFromLatLonInM(prevPoint[0],prevPoint[1],lat,lng)
              }              
              prevPoint = [lat,lng]
              simPoints.push([lat,lng,currentSettings.location.simSpeed.value/3.6])
            }
            myLineString.eachLatLngAlt(eachFn);
        });
        updateSimList(simPoints)
      }
    }

    const getDistanceFromLatLonInM=(lat1, lon1, lat2, lon2)=> {
      var R = 6371000; // Radius of the earth in m
      var dLat = deg2rad(lat2-lat1);  // deg2rad below
      var dLon = deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in m
      return d;
    }
    
    function deg2rad(deg) {
      return deg * (Math.PI/180)
    }

    const getSimData = (point:string) =>{
        return currentSettings.location[point].value
    }

    const getZoneOnMap = async (gmlpolygon)=>{
      let coordList: number[][][]=[];

      gmlpolygon.gmlPolygon.forEach((polygon) => {
        let posList = polygon.exterior.posList.split(" ");
        let coords: number[][] = [];
        for (let i=0;i<posList.length;i += 2){
          //needs lat long, so we invert here
          coords.push([parseFloat(posList[i+1]), parseFloat(posList[i])])
        }
        coordList.push(coords)
      })
      let output = ""
      let url = `https://image.maps.hereapi.com/mia/v3/base/mc/overlay:padding=50/600x400/png?apiKey=${apiKey}&style=${currentSettings.map.style.value}&scaleBar=km&geojson={"type":"MultiPolygon","coordinates":[${JSON.stringify(coordList)}]}`
      
      await fetch(url)
          .then(response => response.blob())
          .then(data => output=URL.createObjectURL(data) )
          .catch((err) => {
              console.log(err.message);            
          });
        setMapCache(output);
        
        return output;      
    }

    const contextValue = {
        getMap,
        getRoute,
        getSimData,
        route,
        getZoneOnMap
      };
    
      return (
        <HereContext.Provider value={contextValue}>
          {props.children}
        </HereContext.Provider>
      );
}

export function useHere() {
    return useContext(HereContext);
}
    