import {
  TdsBadge,
  TdsButton,
  TdsFooter,
  TdsHeader,
  TdsHeaderBrandSymbol,
  TdsHeaderDropdown,
  TdsHeaderDropdownList,
  TdsHeaderDropdownListItem,
  TdsHeaderDropdownListUser,
  TdsHeaderHamburger,
  TdsHeaderTitle,
  TdsIcon,
  TdsSideMenu,
  TdsSideMenuCloseButton,
  TdsSideMenuCollapseButton,
  TdsSideMenuItem,
  TdsSideMenuOverlay,
} from "@scania/tegel-react";
import "./App.css";
import { useRef, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {useUserContext} from "./context/userContext";
import { useWebSocketContext } from "./context/websocketContext";

function App() {
  const sideMenuRef = useRef<HTMLTdsSideMenuElement>(null);
  const [bannerPresent, setBannerPresent] = useState(true);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const {getUserName,handleLogout}  = useUserContext();
  const { connectionStatus } = useWebSocketContext();
  return (
    <div className="App">
      <div className={`banner-header ${!bannerPresent ? "no-banner" : ""}`}>

        <TdsHeader>
          <TdsHeaderHamburger
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
            aria-label="Open application drawer"
          ></TdsHeaderHamburger>
          <TdsHeaderTitle>Smart Urban Traffic Zones 3</TdsHeaderTitle>
          <TdsHeaderDropdown onClick={()=>{}} slot="end" no-dropdown-icon>
          <div slot="icon">
            <TdsIcon name={connectionStatus==="Open"?"wifi":"wifi_inactive"} size="32px"></TdsIcon>
            </div>
          </TdsHeaderDropdown>
          <TdsHeaderDropdown onClick={() => {}} slot="end" no-dropdown-icon>
          <div slot="icon">
            <img
              src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
              alt="User menu."
            />
            </div>
          <TdsHeaderDropdownList size="lg">
            <TdsHeaderDropdownListUser
              header={/*userContext?.user.userName*/getUserName()}
              subheader={/*userContext?.user.placeOfWork*/"scania"}
            ></TdsHeaderDropdownListUser>
            <TdsHeaderDropdownListItem>
              <Link to="settings">
                <TdsIcon name="settings"></TdsIcon>
                <div className="tds-u-pl1">Settings</div>
              </Link>
            </TdsHeaderDropdownListItem>
            <TdsHeaderDropdownListItem>
              <Link to="notifications">
                <TdsBadge value={"0"/*userContext?.user.notifications?.length.toString()*/}></TdsBadge>
                <div className="tds-u-pl1">Notifications</div>
              </Link>
            </TdsHeaderDropdownListItem>
            <TdsHeaderDropdownListItem>
            <TdsButton
          onClick={() => {
            handleLogout()
          }}
          variant="primary"
          size="lg"
          fullbleed
          text="Logout"
        ></TdsButton>
            </TdsHeaderDropdownListItem>
          </TdsHeaderDropdownList>
        </TdsHeaderDropdown>
          <TdsHeaderBrandSymbol slot="end">
          <a aria-label="Scania - red gryphon on blue shield" href="/"></a>
        </TdsHeaderBrandSymbol>
        </TdsHeader>
      </div>

      <div
        className={`tds-u-flex tds-u-h-100 container ${!bannerPresent ? "no-banner" : ""}`}
      >
        <TdsSideMenu
          open={mobileNavOpen}
          ref={sideMenuRef}
          className={`side-menu ${!bannerPresent ? "no-banner" : ""}`}
          persistent
        >
          <TdsSideMenuCloseButton
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          ></TdsSideMenuCloseButton>
          <TdsSideMenuOverlay slot="overlay"></TdsSideMenuOverlay>

          <TdsSideMenuItem>
            <Link to={"/"}>
              <TdsIcon name="home" size="24"></TdsIcon>
              Home
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuItem>
            <Link to={"/permits"}>
              <TdsIcon name="document_eye" size="24"></TdsIcon>
              Permits
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuItem>
            <Link to={"/dashboard"}>
              <TdsIcon name="speedometer" size="24"></TdsIcon>
              Dashboard
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuItem>
            <Link to={"/location"}>
              <TdsIcon name="pin" size="24"></TdsIcon>
              Location
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuItem>
            <Link to={"/vehicle"}>
              <TdsIcon name="truck" size="24"></TdsIcon>
              Vehicle setup
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuItem>
            <Link to={"/simulator"}>
              <TdsIcon name="global" size="24"></TdsIcon>
              Simulate route
            </Link>
          </TdsSideMenuItem>

          <TdsSideMenuItem>
            <Link to={"/datetime"}>
              <TdsIcon name="clock" size="24"></TdsIcon>
              Time & Date
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuItem>
            <Link to={"/settings"}>
              <TdsIcon name="settings" size="24"></TdsIcon>
              Settings
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuItem>
            <Link to={"/about"}>
              <TdsIcon name="info" size="24"></TdsIcon>
              About
            </Link>
          </TdsSideMenuItem>
          <TdsSideMenuCollapseButton slot="sticky-end">
            Collapse
          </TdsSideMenuCollapseButton>
        </TdsSideMenu>
        <div className="tds-u-flex tds-u-flex-dir-col tds-u-w-100">
          <main className="tds-u-p3">
            <Outlet />
          </main>
          <TdsFooter></TdsFooter>      
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(App);
//export default App;
