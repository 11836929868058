import { useState, useContext, createContext, useEffect } from "react";
import { useSettings } from "./settingsContext";


export type DateTimeContextT = {
    simulateDateTime: ()=>boolean,
    setSimulateDateTime: (b:boolean)=>void,
    setActiveDateTime: (o:string)=>void,
    activeDateTime:  ()=>string,
    activeDateTimeAsDate: ()=>Date
  }
export const DateTimeContext = createContext<DateTimeContextT>({
    simulateDateTime: ()=>false,
    setSimulateDateTime: (b:boolean)=>{},
    setActiveDateTime: (n:string)=>{},
    activeDateTime: ()=>"",
    activeDateTimeAsDate: ()=>new Date()
});

export const DateTimeProvider = (props:any)=>{
    const {currentSettings,updateSettingValue} = useSettings()

    
    const activeDateTime = () =>{
        return currentSettings.datetime.simulateDateTime.value ?  currentSettings.datetime.simulatedDateTime.value : new Date().toISOString()
    }
    const activeDateTimeAsDate = ()=>{
        return new Date(currentSettings.datetime.simulateDateTime.value ?  currentSettings.datetime.simulatedDateTime.value : new Date().toISOString())
    }

    const setActiveDateTime = (n:string)=>{
        updateSettingValue('datetime.simulatedDateTime.value', n)
    }
    const simulateDateTime = ()=>{
        return currentSettings.simulateDateTime.value
    }

    const setSimulateDateTime= (b:boolean)=>{
        updateSettingValue('datetime.simulateDateTime.value',b)
    }

    return <DateTimeContext.Provider value={{simulateDateTime,setSimulateDateTime,activeDateTime, setActiveDateTime,activeDateTimeAsDate}}>
        {props.children}
    </DateTimeContext.Provider>
}



export const useDateTimeContext=()=> {
    return useContext(DateTimeContext);
  }