import React from "react";
import ReactDOM from "react-dom/client";
import { defineCustomElements } from "@scania/tegel-react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import App from "./App";
import HomePage from "./pages/HomePage";
import SettingsPage from "./pages/SettingsPage";
import { UserProvider } from "./context/userContext";
import { SettingsProvider } from "./context/settingsContext";
import { WebSocketProvider } from "./context/websocketContext";
import { ResizeProvider } from "./context/resizeContext";
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { PermitProvider } from "./context/permitContext";
import PermitsPage from "./pages/PermitsPage";
import VehiclePage from "./pages/VehiclePage";
import DashboardPage from "./pages/DashboardPage";
import LocationPage from "./pages/LocationPage";
import { HereProvider } from "./context/hereContext";
import { PositionProvider } from "./context/positionContext";
import SimulatorPage from "./pages/SimulatorPage";
import { DateTimeProvider } from "./context/dateTimeContext";
import DateTimePage from "./pages/DateTimePage";

//index.d.ts

declare global {
  interface NodeRequire {
      /** A special feature supported by webpack's compiler that allows you to get all matching modules starting from some base directory.  */
      context: (
          directory: string,
          useSubdirectories: boolean,
          regExp: RegExp
      ) => any;
  }
}

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: String(process.env.REACT_APP_COGNITO_USER_POOL_ID),
      userPoolClientId: String(process.env.REACT_APP_COGNITO_APP_CLIENT_ID),
    loginWith:{
      oauth: {
          domain: String(process.env.REACT_APP_COGNITO_HOSTED_UI),
          scopes: ["phone", "email", "profile", "openid"],
          redirectSignIn: [String(process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN)],
          redirectSignOut: [String(process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN)],
          responseType: "token",
        },
      }
    }
  }
  },
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "/",
        element: <App />,
        children: [
          {
            path: "",
            element: <HomePage />,
          },
          {
            path: "permits",
            element: <PermitsPage />,
          },
          {
            path: "dashboard",
            element: <DashboardPage />,
          },
          {
            path: "vehicle",
            element: <VehiclePage />,
          },
          {
            path: "simulator",
            element: <SimulatorPage />,
          },
          {
            path: "location",
            element: <LocationPage />,
          },
          {
            path: "settings",
            element: <SettingsPage />,
          },
          {
            path: "about",
            element: <AboutPage />,
          },
          {
            path: "datetime",
            element: <DateTimePage />,
          },
        ],
      },
    ],
  },
]);

defineCustomElements();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PermitProvider>
    <Authenticator.Provider>
    <UserProvider>
      <SettingsProvider>
        {/*<WebSocketProvider>*/}

          <PositionProvider>
          <HereProvider>
            <DateTimeProvider>
              <PermitProvider>
          
            <ResizeProvider>
              <RouterProvider router={router} />
            </ResizeProvider>          
            </PermitProvider>
            
            </DateTimeProvider>
            </HereProvider>
         </PositionProvider>

        {/*</WebSocketProvider>*/}
      </SettingsProvider>
    </UserProvider>
    </Authenticator.Provider>
    </PermitProvider>
  </React.StrictMode>
);

//reportWebVitals();
