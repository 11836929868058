const findProp=(obj, prop) =>{
    var result: any[] = [];
    function recursivelyFindProp(o, keyToBeFound) {
      Object.keys(o).forEach(function (key) {
        if ( key === keyToBeFound ){
            result.push(o[key])
        }
        else{
            if (typeof o[key] === 'object') {
                recursivelyFindProp(o[key], keyToBeFound);
            }
        }        
    })
    }
    recursivelyFindProp(obj, prop);
    return result;      
};
  
const pathEntries = (obj) => [
    [[], obj],
    ...Object (obj) === obj
      ? Object .entries (obj) .flatMap (
          ([k, x]) => pathEntries (x) .map (
            ([p, v]) => [[Array .isArray (obj) ? Number (k) : k, ... p], v]
          )
        )
      : []
  ]
  
const findAllPaths = (predicate) => (o) =>
    [...pathEntries (o)] .filter (([p, v]) => predicate (v, p)) .map (([p]) => p)
  
const findPathsByName = (target) => findAllPaths (({name}) => name == target)

function getAllPaths(obj, key, prev = '') {
    const result: any[] = []
  
    for (let k in obj) {
      let path = prev + (prev ? '.' : '') + k;
  
      if (k == key) {
        result.push(path)
      } else if (typeof obj[k] == 'object') {
        result.push(...getAllPaths(obj[k], key, path))
      }
    }
  
    return result
  }
        
const access = (path:string, object) => {
   if (!path) return null;
   return path.split('.').reduce((o, i) => o[i], object)

 }
 

export {
    findProp,
    pathEntries,
    findAllPaths,
    findPathsByName,
    getAllPaths,
    access
}
