import { useState, useContext, createContext, useEffect } from "react";

const localFileName = 'settings_v7' ?? 'settings';
const defaultSettings = {
  general:{
    actOnResize: {type:'bool',value:false,text:"React on resize, effects the dashboard, problems on mobile devices if on"},
  },
  permits:{
    loggingInConsolePermits: {type:'bool',value:false,text:"Logging in console"},
    showAvailableAndActiveTable: {type:'bool',value:false,text:"Show table with available and active permits"},
    showRawDetailsTable: {type:'bool',value:false,text:"Show raw table"}
  },  
  datetime:{
    simulateDateTime:{type:'bool',value:true,text:"Simulate Date and Time"},
    simulatedDateTime:{type:'datetime',value:'2024-08-20 05:00', text:"Simulated Date and Time (UTC timezone)"}
  },
  vehicle:{
    axel1: {type:'int',value:7000,text:"First axel weight in kg",min:1000,max:9000,step:500, unit:"kg"}, 
    axel2: {type:'int',value:11000,text:"Second axel weight in kg",min:1000,max:13000,step:500, unit:"kg"}, 
    axel3: {type:'int',value:0,text:"Third axel weight in kg, 0 for none",min:0,max:12000,step:500, unit:"kg"}, 
    axel4: {type:'int',value:0,text:"Forth axel weight in kg, 0 for none",min:0,max:12000,step:500, unit:"kg"}, 
    trailer: {type:'int',value:0,text:"Trailer weight in kg, 0 for none",min:0,max:50000,step:1000, unit:"kg"}, 
  },
  location:{
    useSimulator: {type:'bool',value:true,text:"Simulate position"},
    simStart:{type:"str",value:"u6sce1ntt00t",text:"Start position in geohash (moraberg u6s8f0emv3j0)"},
    useRoute: {type:'bool',value:false,text:"Create a route by using endpoint in next field"},
    simEnd: {type:"str",value:"u6sce2kudkje",text:"End position in geohash, only used if route is selected above (u6knzd3br)"},
    simEpsg: {type: "str", value:"3006",text:"EPSG number"},
    simSpeed: {type:"int", value:25, text:"Speed of simulator", min: 1,max:100,step:5,unit:"km/h"},
    simSampleTime: {type:'int',value:2, text: "Time between simulator updates", min:1,max:120,step:1,unit:"s"},
  },
  connection:{
    sendDataViaWS: {type:'bool',value:false,text:"Send data to backend"},
    sendInterval: {type:'int',value:10,text:"Interval in seconds",min:1,max:120,step:1, unit:"s"},
    messagesInHistoryWS: {type:'int',value:15,text: "Message to keep in history",min:1,max:200,step:1,unit:"-"},
    loggingInConsoleForWS: {type:'bool',value:false,text:"Logging in console"}
  },
  map:{
    style:{type: "dropdown",value:"logistics.day",options:[
      "explore.day",
      "explore.night",
      "explore.satellite.day",
      "lite.day",
      "lite.night",
      "lite.satellite.day",
      "logistics.day",
      "logistics.night",
      "logistics.satellite.day",
      "satellite.day",
      "topo.day",
      "topo.night"
    ],text:"Map style"},
    zoom: {type:'int',value:10,text:"Zoom level",min:1,max:18,step:1, unit:"-"},
  }
  /*area:{ //moved to each area
    autoSubscribe: {type: "bool", value: false, text:"Auto start sensor data fetching"},
    xscale:{type: "float", value: 1, text:"X scale", min:0.1, max: 100, step:0.1, unit:"-" },
    yscale:{type: "float", value: 1, text:"Y scale", min:0.1, max: 100, step:0.1, unit:"-" },
    showGrid:{type: "bool", value: true, text:"Show grid"},
    gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
    loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
  },
  sensor:{
    decimalPlacesPosition: {type:'int',value:6,text:"Decimal places to show for position",min:1,max:15,step:1, unit:"count"},
    decimalPlacesVelocity: {type:'int',value:2, text:"Decimal places to show for velocity",min:1,max:15,step:1, unit:"count"},
    loggingInConsoleSensor: {type:'bool',value:false,text:"Logging in console"}
  },

    stc:
      {
        name: {type: "str",value:'Scania STC',text: "Area Name"},
        shortname: {type: "str",value:'stc',text: "Area Name",enabled:false},
        topic: {type: "area", text:"Topics",rows:5, value:"viscando.testtrack.+,kth.+.status.+,scania.stc.status.+"},
        origin: {type: "area", text:"Centerpoint",rows:2, value: [17.62529591,59.16628516],enabled:"false"},
        view: {type: "area", text:"View area sensor",row:6, value: [
                [17.625492396118304,59.166494959148245],
                [17.625602573220817,59.166478755799332],
                [17.625797352648455,59.166436918828190],
                [17.625881417981404,59.166330123064085],
                [17.625827826843420,59.166211655130923],
                [17.625363539820853,59.166285365155005],
                [17.625387070959555,59.166462592686393],
                [17.625533554041589,59.166628310125411],
                [17.625446778742297,59.166633671709029],
                [17.625085970465687,59.166584045950991],
                [17.624816495405767,59.166455184934023],
                [17.624905155877212,59.166385667096677],
                [17.625222637201500,59.166311879895353],
        ],enabled:"false"},
        projection: {type: "dropdown",value:"SWEREF99",options:["WGS84","SWEREF99","LOCAL"],text:"Projection for control map"},
        A:{type: "area",text:"A point",rows:2, value:[17.626074279383257,59.166247140128249],enabled:"false"},
        B:{type: "area",text:"B point",rows:2, value:[17.625630052546782,59.166594834957571],enabled:"false"},
        C:{type: "area",text:"C point",rows:2, value:[17.624864033452997,59.166513769736220],enabled:"false"},
        speed: {type:"float", value: 1, text:"Max speed in m/s during task execution", min:0.1,max: 10, step: 0.1, unit:'m/s'},
        xscale:{type: "float", value: 3, text:"X scale", min:0.1, max: 100, step:0.1, unit:"-" },
        yscale:{type: "float", value: 3, text:"Y scale", min:0.1, max: 100, step:0.1, unit:"-" },
        showGrid:{type: "bool", value: true, text:"Show grid"},
        gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
        loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
      },
      valhal:
      {
        name: {type: "str",value:'Valhal (Simulation)',text: "Area Name"},
        shortname: {type: "str",value:'valhal',text: "Area Name", enabled:false},
        topic: {type: "area", text:"Topics",rows:5, value:"scania.valhal.sensor.+.tracks,scania.valhal.status.+"},
        origin: {type: "area", text:"Centerpoint",rows:2, value: [0,0],enabled:"false"},
        view: {type: "area", text:"View area sensor",rows:6, value: [],enabled:"false"},
        projection: {type: "dropdown",value:"LOCAL",options:["WGS84","SWEREF99","LOCAL"],text:"Projection"},
        A:{type: "area",text:"A point",rows:2, value:[50,0],enabled:"false"},
        B:{type: "area",text:"B point",rows:2, value:[0,50],enabled:"false"},
        C:{type: "area",text:"C point",rows:2, value:[80,80],enabled:"false"},
        speed: {type:"float", value: 1, text:"Max speed in m/s during task execution", min:0.1,max: 10, step: 0.1, unit:'m/s'},
        xscale:{type: "float", value: 3, text:"X scale", min:0.1, max: 100, step:0.1, unit:"-" },
        yscale:{type: "float", value: 3, text:"Y scale", min:0.1, max: 100, step:0.1, unit:"-" },
        showGrid:{type: "bool", value: true, text:"Show grid"},
        gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
        loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
      },
      itrl:
      {
        name: {type: "str",value:'ITRL',text: "Area Name"},
        shortname: {type: "str",value:'itrl',text: "Area Name",enabled:false},
        topic: {type: "area", text:"Topics",rows:5, value:"kth.itrl.sensor.+.tracks,kth.itrl.status.+"},
        origin: {type: "area", text:"Centerpoint",rows:2, value: [17.62529591,59.16628516],enabled:"false"},
        view: {type: "area", text:"View of sensor",rows:6, value: [0,0],enabled:"false"},
        projection: {type: "dropdown",value:"WGS84",options:["WGS84","SWEREF99","LOCAL"],text:"Projection"},
        A:{type: "area",text:"A point",rows:2, value:[1,0],enabled:"false"},
        B:{type: "area",text:"B point",rows:2, value:[0,1],enabled:"false"},
        C:{type: "area",text:"C point",rows:2, value:[2,2],enabled:"false"},
        speed: {type:"float", value: 1, text:"Max speed in m/s during task execution", min:0.1,max: 10, step: 0.1, unit:'m/s'},
        xscale:{type: "float", value: 1, text:"X scale", min:0.1, max: 100, step:0.1, unit:"-" },
        yscale:{type: "float", value: 1, text:"Y scale", min:0.1, max: 100, step:0.1, unit:"-" },
        showGrid:{type: "bool", value: true, text:"Show grid"},
        gridSpacing:{type: "int", value: 10, min:1, max:200,step:1,unit:"-", text:"Grid spacing"},
        loggingInConsoleArea: {type:'bool',value:false,text:"Logging in console"}
      },
  /*dashboard:{
    showDevParameters:{type:'bool',value:false,text:"Show develop parameters"},
    drawInfoDisplayDebug:{type:'bool',value:false,text:"Show debug info for warning display"},
    drawLaneDebug:{type:'bool',value:false,text:"Show Lane drawing debug lines"},
    drawLaneInfoDebug:{type:'bool',value:false,text:"Show Lane information debug info"},
    maxSpeedVehicle: {type:'int',value:90,text:"Maximum speed of vehicle",min:30,max:130,step:10,unit:"km/h"},
    warningDisplayTimeOut:{type:'int',value:20,text:"Time to display received warning",min:1,max:60,step:1,unit:"s"},
    laneCountingFromLeft:{type:'bool',value:false,text:"Start lane number 1 from left instead of right"},
  },
  location:{
    useSimulator: {type:'bool',value:true,text:"Simulate position"},
    simStart:{type:"str",value:"u6scfd3d4exq",text:"Start position in geohash (moraberg u6s8f0emv3j0)"},
    useRoute: {type:'bool',value:false,text:"Create a route by using endpoint in next field"},
    simEnd: {type:"str",value:"u6sfhmyp4j6q",text:"End position in geohash, only used if route is selected above (u6knzd3br)"},
    simEpsg: {type: "str", value:"3006",text:"EPSG number"},
    simSpeedMs: {type:"int", value:25, text:"Speed of simulator", min: 1,max:250,step:10,unit:"m/s"},
    simSampleTime: {type:'int',value:5, text: "Time between simulator updates", min:1,max:120,step:1,unit:"s"},
  },
  map:{
    mapZoomLevel: {type:"int", value:16, text:"Zoom level", min: 1,max:20,step:1,unit:"-"},
    eventFilter:{type: "str", value:"SE",text:"Filter publisher id starting with this string"},
    fetchExternalData:  {type:'bool',value:false,text:"Fetch external data (not interchange)"},
    eventFetcher: {type:"int", value:60, text:"Fetch external data at this interval", min: 30,max:300,step:30,unit:"s"},
    clusteringEps: {type:"int", value:32, text:"Maximum radius of the neighbourhood",min:1,max:200,step:1,unit:"-"},
    clusteringMinWeight: {type:"int", value:10, text:"Minimum weight of points required to form a cluster",min:1,max:200,step:1,unit:"-"}
  },*/
};
export type SettingsContextT = {
  currentSettings: any
  setSettings: (o:object)=> void
  setToDefaultSettings: ()=>void
  updateSettingValue: (path:string, value:any)=>void
}
export const SettingsContext = createContext<SettingsContextT>({
  currentSettings: defaultSettings,
  setSettings: ()=>{},
  setToDefaultSettings: ()=>{},
  updateSettingValue: ()=>{},
});

export function SettingsProvider(props) {
  const [currentSettings, setSettings] = useState(() => {
    try {
      return {...defaultSettings,...JSON.parse(localStorage.getItem(localFileName)||'{}')} ?? defaultSettings;
    } catch {
      return defaultSettings;
    }
  });

  const setToDefaultSettings = ()=>{
    setSettings(defaultSettings);
  }

  

  const contextValue = {
    currentSettings,
    setSettings,
    setToDefaultSettings,
    updateSettingValue: (path:string, value:any)=>{
      let newSettings = {...currentSettings};
      let keys = path.split('.');
      let obj = newSettings;
      for (let i = 0; i < keys.length - 1; i++) {
        obj = obj[keys[i]];
      }
      obj[keys[keys.length - 1]] = value;
      setSettings(newSettings);
      }
}


  useEffect(()=>{
    localStorage.setItem(localFileName, JSON.stringify(currentSettings));
  }, [currentSettings])

  return (
    <SettingsContext.Provider value={contextValue}>
      {props.children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  return useContext(SettingsContext);
}