import { usePermit, JsonFile} from "../context/permitContext";
import { TdsAccordion, TdsBodyCell, TdsDropdown, TdsDropdownOption, TdsHeaderCell, TdsInlineTab, TdsInlineTabs, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableHeader, TdsTextarea } from "@scania/tegel-react";
//import PermitDetailsTable from "../components/permits/PermitDetailsTable";
import React, { Component, useState } from 'react'
import JSONGrid from '@redheadphone/react-json-grid'
import { useSettings } from "../context/settingsContext";
import { access, findPathsByName, findProp, getAllPaths } from "../tools/Nested";
import  RegulationTimeDisplay from "../components/permits/PermitsTime";
import TrafficRegulations from "../components/permits/TrafficRegulations";
import HereMap from "../components/map/NewHere";
interface NestedData {
    label: string;
    children?: NestedData[];
}

const PermitsPage = () => {
    // search for .json files
    const {permitFiles,activePermit,setActivePermit,getNrRegulations,getActivePermitData} = usePermit()
    const [activeTab, setActiveTab] = useState(0)
    const {currentSettings} = useSettings()
    const getShortName = (name:string) =>{
        for (let index = 0; index < permitFiles.length; index++) {
          if (permitFiles[index].fileName===name){
            return index
          }  
        }
        return -1
    }

    const regulationDisplay=()=>{
        let allTrafficRegulations = getAllPaths(permitFiles[activePermit].fileData, 'controlledZoneControlledZoneRegulation');
        return (
            <>
            <p>{`The current permit has ${getNrRegulations()} traffic regulation orders`}</p>
            <TdsAccordion>
            {getActivePermitData().map((o:any,idx:number)=>{
                //let x = access(o,permitFiles[activePermit].fileData) 
                return( 
                  <TrafficRegulations key={permitFiles[activePermit].fileName + idx} index={idx} data={o}></TrafficRegulations>
                  ) 
            })            
            }
            </TdsAccordion>
            </>
        )
    }

    
    const tabNames = [
        "General","Vehicle","Time","Location","Regulations"
    ]
    const tabData = {
        General:{
            pubdate:{"label":"Publish Date",
                    "field":"permitFiles[activePermit].fileData.trafficRegulationTrafficRegulationPublication.publicationTime"},
            issuer:{"label":"Issuer",
                    "field":"permitFiles[activePermit].fileData.trafficRegulationTrafficRegulationPublication.trafficRegulationsFromCompetentAuthorities.trafficRegulationOrder[0].issuingAuthority.values[0].value"},
            description:{"label":"Description",
                    "field":"permitFiles[activePermit].fileData.trafficRegulationTrafficRegulationPublication.trafficRegulationsFromCompetentAuthorities.trafficRegulationOrder[0].description.values[0].value"},
            regulationid:{"label":"Reference",
                    "field":"permitFiles[activePermit].fileData.trafficRegulationTrafficRegulationPublication.trafficRegulationsFromCompetentAuthorities.trafficRegulationOrder[0].regulationId"},
        },
        //Time: RegulationTimeDisplay,
        //Vehicle:{},
        //Location:{},
        Regulations: regulationDisplay,
    }



    const handleChangeDropdown=(e:any)=>{
        setActivePermit(getShortName(e.detail.value))
        let c = findProp(permitFiles[getShortName(e.detail.value)].fileData,"overallStartTime")
        let b = 1
        findPathsByName("overallStartTime")(permitFiles[getShortName(e.detail.value)].fileData)
        const result = getAllPaths(permitFiles[getShortName(e.detail.value)].fileData, 'trafficRegulationGrossWeightLimit');
        if (result.length > 0){
        let x = access(result[0],permitFiles[getShortName(e.detail.value)].fileData)
        let d = 1
        }
    }
    const selectPermit=()=>{
        return(
            <>
            
            <TdsDropdown
                name="dropdown"
                label="Select Active Permit"
                default-value={permitFiles[activePermit].fileName}
                label-position="inside"
                size="lg"
                open-direction="auto"
                onTdsChange={e=>handleChangeDropdown(e)}                
            >
            {permitFiles.map((file:JsonFile) => (
             <TdsDropdownOption key={file.fileName} value={file.fileName}>{file.fileName}</TdsDropdownOption>
            )
            )
            }
          </TdsDropdown>
          </> 
        )
    }

    const PermitsTable = () => {
        return (
            <TdsTable vertical-dividers="false" compact-design="false" responsive>
            <TdsTableHeader>
                <TdsHeaderCell cell-key="availablePermits" cell-value="Availabe Permits"></TdsHeaderCell>
                <TdsHeaderCell cell-key="activePermit" cell-value="Active"></TdsHeaderCell>
            </TdsTableHeader>
            <TdsTableBody>
                <TdsTableBodyRow>
                <TdsBodyCell
                    cell-value=""
                    cell-key="availablePermits"
                     disable-padding="false"
                > {permitFiles.map((file)=>( <div key={file.fileName}>{file.fileName}</div>))}
                </TdsBodyCell>
                <TdsBodyCell
                    cell-value={permitFiles[activePermit].fileName}
                    cell-key="activePermits"
                     disable-padding="false"
                > 
                </TdsBodyCell>
                </TdsTableBodyRow>
                
            </TdsTableBody>
            </TdsTable>
        );
      };
    



    const PermitsDetails=(data:NestedData)=>{            
        return(
            <JSONGrid data={data} defaultExpandDepth="2"/>
        )
    };

    const onTabChange=(e:any)=>{
        setActiveTab(e.detail.selectedTabIndex)
    }

    const tableData=(field:any)=>{
        return(
            <TdsTable>
                <TdsTableHeader>
                    <TdsHeaderCell cell-value="Field"></TdsHeaderCell>
                    <TdsHeaderCell cell-value="Value"></TdsHeaderCell>
                </TdsTableHeader>
                <TdsTableBody>
                    {Object.entries(field).map(([key, value]) => (
                    <TdsTableBodyRow key={key}>
                        <TdsBodyCell>{value!["label"]}</TdsBodyCell>
                        <TdsBodyCell>{eval(value!["field"])}</TdsBodyCell>
                    </TdsTableBodyRow>
                    ))}
                </TdsTableBody>
            </TdsTable>
        )
    }
    const renderSwitch=(sw:number)=>{
        const tabName = Object.keys(tabData)[sw]    
        return typeof(tabData[tabName])==="function"?tabData[tabName]():<div>{tableData(tabData[tabName])}</div>      
    }
    const PermitsNice=(data:NestedData)=>{
        return(
            <>
            <TdsInlineTabs onTdsChange={e=>onTabChange(e)} modeVariant="secondary" defaultSelectedIndex={activeTab}>
                {Object.keys(tabData).map(n=>{
                    return(
                        <TdsInlineTab key={n}>
                            <button>{n}</button>
                        </TdsInlineTab>
                    )}
                )}
            </TdsInlineTabs>
            {renderSwitch(activeTab)}
            </>
        )
    }

    return (
      <>
        <h1 className="tds-headline-01 tds-u-pb3">Permits</h1>
        <div className="tds-u-w-75">
        {/*<HereMap showPos={ false } draggable={ false } followPosition={false} noUI={true} showRoute={false} showZones={true}/>*/}
        
        {selectPermit()}
        <br></br>
        {PermitsNice(permitFiles[activePermit].fileData)}
        {currentSettings.permits.showAvailableAndActiveTable.value ? PermitsTable():null}
        {currentSettings.permits.showRawDetailsTable.value ? PermitsDetails(permitFiles[activePermit].fileData):null}
        </div>
      </>
    );
  };
  
  export default PermitsPage;