import { TdsDatetime, TdsDivider } from "@scania/tegel-react";
import SettingsParagraph from "../components/settings/SettingsParagraph";
import SettingsParameter from "../components/settings/SettingsParameter";
import { useDateTimeContext } from "../context/dateTimeContext";
import { useSettings } from "../context/settingsContext";
import { useState } from "react";

const DateTimePage = () => {
    const { simulateDateTime,setSimulateDateTime,activeDateTime,setActiveDateTime} = useDateTimeContext();
    const {currentSettings} = useSettings();

    const updateTime=(ev)=>{
      console.log(ev.detail.target.value);
      setActiveDateTime(ev.detail.target.value);
    }
    return (
      <>
        <h2 className="tds-headline-01 tds-u-pb3">Time</h2>
        <div className="tds-u-flex-center">
        <p className="tds-body-01 tds-u-align-start">Here you can change the "current" time and date</p>
        </div>
        <br></br>
        <SettingsParameter name="simdatetime" path="datetime.simulateDateTime"/>
        <br></br>
        { currentSettings.datetime.simulateDateTime.value ? 
          <TdsDatetime id="datetime" defaultValue={activeDateTime()} type="datetime-local" size="lg" state="none" label="Pick the new time" helper="When simulating, the time will not change" onTdsChange={e => updateTime(e)}></TdsDatetime>
        :`Using current date time: ${activeDateTime()}`
        }
        <br></br><br></br>
        Time used: {activeDateTime()}
      </>
    );
  };
  
export default DateTimePage;
  