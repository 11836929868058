import { useState, createContext, useContext, useEffect } from "react";
import useWebSocket, { ReadyState, SendMessage } from "react-use-websocket";
import { useUserContext } from "./userContext";
import { useSettings } from "./settingsContext";

export type WebSocketContextT = {
  sendMessage: SendMessage,
  readyState: ReadyState,
  connectionStatus: string,
  messageHistory: [],
  sensorData: {},
  latencyData: {},
  lastJsonMessage: any,
}

export const WebSocketContext = createContext<WebSocketContextT>(
  {
    sendMessage: ()=>{},
    readyState: ReadyState.UNINSTANTIATED,
    connectionStatus: "Unkown",
    messageHistory: [],
    sensorData: {},
    latencyData: {},
    lastJsonMessage: {},
  }
);

export function WebSocketProvider(props) {
  const { accessToken, validLogin } = useUserContext();
  const { currentSettings  } = useSettings();
  const [url, setUrl] = useState<string|null>(null)
  //const Url = process.env.REACT_APP_WEBSOCKET_URL + "/?token=" + accessToken;
  const [messageHistory, setMessageHistory] = useState<any>([]);
  const [sensorData, setSensorData] = useState<any>({});
  const [latencyData, setLatencyData] = useState<any>({});
  const [connectionStatus, setConnectionStatus] = useState("Unkown");
  const { sendMessage, lastMessage, readyState, lastJsonMessage } = useWebSocket(url,{
    onOpen: () => console.log('Websocket opened'),
    onClose: () => console.log("Websocket closed"),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    //attemptNumber will be 0 the first time it attempts to reconnect, so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds, and then caps at 10 seconds until the maximum number of attempts is reached
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    heartbeat: {
        message: '{"action":"ping", "message":"ping"}',
        returnMessage: 'pong',
        timeout: 120000, // 1 minute, if no response is received, the connection will be closed
        interval: 60000, // every 25 seconds, a ping message will be sent
      },
  },true);

  useEffect(()=>{
    if (typeof(readyState) !== 'undefined' && readyState != null){
      setConnectionStatus({
        [ReadyState.CONNECTING]: "Connecting",
        [ReadyState.OPEN]: "Open",
        [ReadyState.CLOSING]: "Closing",
        [ReadyState.CLOSED]: "Closed",
        [ReadyState.UNINSTANTIATED]: "Uninstantiated",
      }[readyState])
    }
    else{
      setConnectionStatus("Unknown")
    }
  },[readyState])

  useEffect(()=>{
    if (validLogin) {
      if (currentSettings.connection.loggingInConsoleForWS.value===true) console.log("Have valid token, update websocket")
      setUrl(process.env.REACT_APP_WEBSOCKET_URL + "/?token=" + accessToken)
    }else{
      setUrl(null)//null makes it not connect
    }
  },[accessToken,validLogin, currentSettings.connection.loggingInConsoleForWS.value]
  )

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory(prev => [lastMessage].concat(prev.slice(0, currentSettings.connection.messagesInHistoryWS.value-1)));
      if (currentSettings.connection.loggingInConsoleForWS.value===true) console.log("WebSocket: incoming", lastMessage)
    }
  }, [lastMessage, setMessageHistory,currentSettings.connection.messagesInHistoryWS.value,currentSettings.connection.loggingInConsoleForWS.value ]);

  useEffect(() => {
    if (lastJsonMessage !== null && Object.keys(lastJsonMessage!).length>0) {
      if (lastJsonMessage!.hasOwnProperty('topic')){
        let topic =lastJsonMessage!["topic"]
        delete lastJsonMessage!["topic"]
        let tmp = {[topic]: lastJsonMessage}
        if (topic.includes("/tracks")) {
            setSensorData((prev) => ({...prev,...tmp}));
        }
        if (topic.includes("/latency")){
            setLatencyData((prev)=>({...prev,...tmp}))
        }
      }
    }
  },[lastJsonMessage])

//  useEffect(() => {
//    if (lastJsonMessage !== null) {
//      if (lastJsonMessage.hasOwnProperty('eventfetch')){
//        console.log("Received list of events");
//        // Decode base64 (convert ascii to binary)
//        const strData = window.atob(lastJsonMessage.eventfetch);
//        // Convert binary string to character-number array
//        const charData = strData.split('').map(function(x) {
//          return x.charCodeAt(0);
//        });
//        // Turn number array into byte-array
//        const binData = new Uint8Array(charData);
//        // Pako magic
//        const data = pako.inflate(binData);
//        // Convert gunzipped byteArray back to ascii string:
//        const strOutData = String.fromCharCode.apply(null, new Uint16Array(data));
//        if (lastJsonMessage.current_chunk===1){
//          //it is the first data, put in in temp storage
//          eventsTempData.current =JSON.parse(strOutData);
//        }else{
//          //add next chunkt to temp storage
//          eventsTempData.current = eventsTempData.current.concat(JSON.parse(strOutData));
//        }
//        if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && eventsTempData.current.length>0){
//          //now all data has arrived, set it to the output value
//          console.log("All event data received")
//          setEventMessage(eventsTempData.current);
//        }
//      } else if (lastJsonMessage.hasOwnProperty('snap')){
//        console.log("Received snap to road data");
//        setSnapMessage(lastJsonMessage.snap);
//      } else if (lastJsonMessage.hasOwnProperty("graphs")){
//        console.log("Received graph data");
//        if (lastJsonMessage.hasOwnProperty("current_chunk")){
//          if (lastJsonMessage.current_chunk===1){
//            //it is the first data, put in in temp storage
//            graphsTempData.current =lastJsonMessage.graphs;
//          }else{
//            //add next chunkt to temp storage
//            graphsTempData.current = graphsTempData.current.concat(lastJsonMessage.graphs);
//          }
//          if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && graphsTempData.current.length>0){
//            //now all data has arrived, set it to the output value
//            console.log("All graphs data received")
//            setGraphsMessage(graphsTempData.current);
//          }
//        } else {
//          setGraphsMessage(lastJsonMessage.graphs)
//        }
//      } else if (lastJsonMessage.hasOwnProperty("vehicles")){
//        console.log("Received vehicle data");
//        // Decode base64 (convert ascii to binary)
//        const strData = window.atob(lastJsonMessage.vehicles);
//        //var strData = (lastJsonMessage.vehicles.toString('base64'));
//        //var strData = Buffer.from(lastJsonMessage.vehicles, 'base64').toString('utf8')
//        // Convert binary string to character-number array
//        const charData = strData.split('').map(function(x) {
//          return x.charCodeAt(0);
//        });

//        // Turn number array into byte-array
//        const binData = new Uint8Array(charData);

//        // Pako magic
//        const data = pako.inflate(binData);
//        // Convert gunzipped byteArray back to ascii string:
//        const strOutData = String.fromCharCode.apply(null, new Uint16Array(data));
//        if (lastJsonMessage.current_chunk===1){
//          //it is the first data, put in in temp storage
//          //setVehiclesTempData(JSON.parse(strOutData));
//          vehiclesTempData.current =JSON.parse(strOutData);
//        }else{
//          //add next chunkt to temp storage
//          //setVehiclesTempData(prev => prev.concat(JSON.parse(strOutData)));
//          vehiclesTempData.current = vehiclesTempData.current.concat(JSON.parse(strOutData));
//        }
//        if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && vehiclesTempData.current.length>0){
//          //now all data has arrived, set it to the output value
//          console.log("All vehicle data received")
//          setVehiclesMessage(vehiclesTempData.current);
//          //vehiclesTempData.current = [];
//        }
//      }
//      else if (lastJsonMessage.hasOwnProperty('simulator')){
//        console.log("Received list of simulator points");
//        // Decode base64 (convert ascii to binary)
//        const strData = window.atob(lastJsonMessage.simulator);
//        // Convert binary string to character-number array
//        const charData = strData.split('').map(function(x) {
//          return x.charCodeAt(0);
//        });
//        // Turn number array into byte-array
//        const binData = new Uint8Array(charData);
//        // Pako magic
//        const data = pako.inflate(binData);
//        // Convert gunzipped byteArray back to ascii string:
//        const strOutData = String.fromCharCode.apply(null, new Uint16Array(data));
//        if (lastJsonMessage.current_chunk===1){
//          //it is the first data, put in in temp storage
//          simulatorTempData.current =JSON.parse(strOutData);
//        }else{
//          //add next chunkt to temp storage
//          simulatorTempData.current = simulatorTempData.current.concat(JSON.parse(strOutData));
//        }
//        if (lastJsonMessage.current_chunk === lastJsonMessage.total_chunks && simulatorTempData.current.length>0){
//          //now all data has arrived, set it to the output value
//          console.log("All simulator data received")
//          setSimulatorMessage(simulatorTempData.current);
//        }
//      }else if (lastJsonMessage.hasOwnProperty("warning")){
//        console.log("Received warning");
//        setWarningMessage(lastJsonMessage)
//      }else if (lastJsonMessage.hasOwnProperty("mcs")){
//        console.log("Received MCS data");
//        setMcsMessage(lastJsonMessage.mcs);
//      }
//    }
//  },[lastJsonMessage] )

  //useEffect(()=>{
  //  console.log("Restarting position sending");
  //  if (currentSettings.sendDataViaWS){
  //    const timerId2 = setInterval(()=>{
  //    console.log("Sending position message to backend " +JSON.stringify(positionValue.current) );

  //      sendMessage(`{"action":"position", "message":${JSON.stringify(positionValue.current)}}`)
  //    },currentSettings.sendInterval*1000);
  //  return () => clearInterval(timerId2);
  //  }

  //},[currentSettings.sendInterval,currentSettings.sendDataViaWS, sendMessage]);


  const contextValue = {
    sendMessage,
    readyState,
    connectionStatus,
    messageHistory,
    sensorData,
    latencyData,
    lastJsonMessage,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {props.children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocketContext() {
  return useContext(WebSocketContext);
}
