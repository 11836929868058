import { act, useEffect, useState } from "react";
import SettingsParagraph from "../components/settings/SettingsParagraph";
import { usePermit } from "../context/permitContext";
import { usePosition } from "../context/positionContext";
import { useSettings } from "../context/settingsContext";
import { useDateTimeContext } from "../context/dateTimeContext";
import { TdsDatetime } from "@scania/tegel-react";
import HereMap from "../components/map/NewHere";
import "./DashboardPage.css";
import { access, getAllPaths } from "../tools/Nested";
import SettingsParameter from "../components/settings/SettingsParameter";
const DashboardPage = () => {
    const { currentSettings} = useSettings();
    const {activeDateTime,activeDateTimeAsDate} = useDateTimeContext()
    const {position} = usePosition()
    const {returnAffectedPermits} = usePermit()
    const [listOfRegulations, setListOfRegulations] = useState<any[] | null>([])

    useEffect(()=>{
        setListOfRegulations(returnAffectedPermits(position,activeDateTime()))
    },[position])

    const getGrossVehicleWeight = () =>{
      let gvw = 0
      Object.values(currentSettings.vehicle).forEach((item:any)=>{
        gvw += item.value
      })
      return gvw
    }

    const printLimit = (param:string, obj:any) =>{
      let data = access(getAllPaths(obj, param)[0],obj)
      let retValue = ""
      if (data!==null && JSON.stringify(data) !== JSON.stringify({}) && data.hasOwnProperty("maxValue")) retValue = param + " " + data.maxValue.numericValue + " " + data.maxValue.unitOfMeasure
      if (data!==null && JSON.stringify(data) !== JSON.stringify({}) && data.hasOwnProperty("values")) retValue = param + " " + data.values[0].value
      let cn = ""
      if (data!==null && JSON.stringify(data) !== JSON.stringify({})){
      switch (param) {
        case "trafficRegulationSpeedLimit":
          retValue = data.maxValue.numericValue +""
          cn = "speedlimit" + (position["speed"]*3.6 <= data.maxValue.numericValue ?"":" toohigh")
          break;
        case "trafficRegulationGrossWeightLimit":
          cn = "weightlimit" + (getGrossVehicleWeight()/1000 <= data.maxValue.numericValue ?"":" toohigh")
          retValue = data.maxValue.numericValue +" t"
          break;
        case "trafficRegulationDriverAdvice":
          cn = "driveradvice"
          retValue = data.values[0].value
      }}
      return retValue.length>0 ? <li className={cn}>{retValue}</li> : null
    }

    const detailsText = (obj:any) =>{
      let details = ["trafficRegulationSpeedLimit","trafficRegulationGrossWeightLimit", "trafficRegulationDriverAdvice"]
      return <ul className="no-bullets">{details.map((detail)=>{return printLimit(detail,obj)})}</ul>
    }

    return (
      <div className="tds-u-w-100">
        <h2 className="tds-headline-01 tds-u-pb3 tds-u-w-100">Dashboard</h2>
        <p className="tds-u-w-100">Current speed {currentSettings.location.simSpeed.value} km/h - Total vehicle weight {getGrossVehicleWeight()/1000} ton</p>
        <div className="tds-grid-container tds-u-w-100">
        <div className="tds-grid-fixed">
        <div className="tds-grid-item" ><HereMap showPos={ true } draggable={ false } followPosition={true} noUI={true} fixedZoom={17} showZones={true} showRoute={true}/></div>
        <div className="tds-grid-item">
        <p className="tds-u-w-100" key="time">Time: {activeDateTimeAsDate().toString()}</p>
        <p key="nr">Current position is affected by {listOfRegulations?.length} permit{listOfRegulations?.length===1?null:"s"}</p>
          {listOfRegulations?.map((o,i)=>{
          return (<><p key={position["geohash"]+"-"+i}>Permit: {i+1}<br></br></p>{detailsText(o)}</>)
        })}</div>

        </div>       
        
        </div>
        <div className="tds-u-w-25">
        <SettingsParameter name="location" path="location.simSpeed"/>
        <SettingsParameter name="vehicle" path="vehicle.trailer"/>
        </div>
      </div>
    );
  };
  
export default DashboardPage;
  