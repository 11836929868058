import {TdsButton } from "@scania/tegel-react";
import {useSettings} from "../context/settingsContext"
import SettingsParagraph from '../components/settings/SettingsParagraph'

const SettingsPage = () => {
    const { currentSettings, setToDefaultSettings} = useSettings();
    return (
        <>
            <h1 className="tds-headline-01 tds-u-mb-2">Settings</h1>
            <div className="tds-u-pb1">
        <TdsButton
          key="defaultSettingsButton"
          onClick={() => {
            setToDefaultSettings()
          }}
          variant="primary"
          size="lg"
          fullbleed
          text="Reset to default"
        ></TdsButton>
      </div>
      <div className="tds-container-fluid">
        {Object.keys(currentSettings).map((key, index) =>{
          return( <SettingsParagraph key={key} path={key} settings={currentSettings} /> )
          })
        }
        </div>

        </>
    );
};

export default SettingsPage;
