import React, { useEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';
import Geohash from "../../tools/Geohash";
import { create } from 'domain';
import { useHere } from '../../context/hereContext';
import { usePosition } from "../../context/positionContext";
import { usePermit } from "../../context/permitContext";
import { useSettings } from '../../context/settingsContext';
import { useResizeContext } from '../../context/resizeContext';
import { useDateTimeContext } from '../../context/dateTimeContext';
//import {decode} from '@here/flexpolyline';


const HereMap = ({showPos=true,showRoute=true,showZones=true,draggable=false,followPosition=false,noUI=false,fixedZoom=0}) => {
    const apiKey = process.env.REACT_APP_HERE_API_KEY;
    const {getSimData,route} = useHere();
    const {position,simState} = usePosition();
    const {getAllZones,checkTiming} = usePermit();
    const {activeDateTime} = useDateTimeContext();
    const {currentSettings,updateSettingValue} = useSettings();
    const {width,height} = useResizeContext()
    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null);
    const svgMarkup = '<svg style="left:-14px;top:-36px;" xmlns="http://www.w3.org/2000/svg" width="28px" height="36px" > <path d="M 13 2.2 C 6 2.2 2.3 7.2 2.1 12.8 C 2.1 16.1 3.1 18.4 5.2 20.5 L 13 28.2 L 20.8 20.5 C 22.9 18.4 23.8 16.2 23.8 12.8 C 23.6 7.07 20 2.2 13 2.2 Z" fill="$COLOR" fill-opacity=""></path> <text transform="matrix( 1 0 0 1 13 18 )" x="0" y="0" fill-opacity="1" fill="#fff" text-anchor="middle" font-weight="bold" font-size="13px" font-family="arial">$TEXT</text></svg>'
    const routeGroup = new H.map.Group();
    const positionGroup = new H.map.Group();
    const zonesGroup = new H.map.Group();
 
    const plotRoute = () =>{
        let sections = route["routes"][0].sections
        const lineStrings = [];
        sections.forEach((section) => {
            // convert Flexible Polyline encoded string to geometry
            lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
        });
        const multiLineString = new H.geo.MultiLineString(lineStrings);
        const bounds = multiLineString.getBoundingBox();
        // Create the polyline for the route
            const routePolyline = new H.map.Polyline(multiLineString, {
            style: {
                lineWidth: 5,
                //fillColor: "rgba(50, 0, 0, 0.4)",
                strokeColor: 'blue',
            } 
        });
        // Remove all the previous map objects, if any
        //map.current.removeObjects(map.current.getObjects());
        routeGroup.removeAll()
        // Add the polyline to the map
        //map.current.addObject(routePolyline);            
        routeGroup.addObjects([
            routePolyline,
            createMarker(Geohash.decode(getSimData("simStart")), "A","location.simStart.value"),
            createMarker(Geohash.decode(getSimData("simEnd")), "B","location.simEnd.value"),
        ])
        map.current.addObject(routeGroup)
    }

    const plotZones = () =>{
        zonesGroup.removeAll()
        let z = getAllZones()
        z.polygon.forEach((zone,idx)=>{
            let color = checkTiming(z.paths[idx],activeDateTime()) ? "rgba(0, 85, 0, 0.4)" : "rgba(85, 0, 0, 0.4)"
            zone.gmlPolygon.forEach((polygon)=>{
                let lineString =  H.geo.LineString.fromLatLngArray(polygon.exterior.posList.split(" ").map((n)=>{return(parseFloat(n))}))
                let p = new H.map.Polygon(lineString, {
                    style: {
                        lineWidth: 4,
                        fillColor: color,
                        strokeColor: color.replace("0.4","1"),
                    }
                })
                zonesGroup.addObject(p)
            })
        })
        map.current.addObject(zonesGroup)
    }

    //position
    useEffect(() =>{  
        if(map.current){
            map.current.removeObjects(map.current.getObjects())
            if (showPos===true){           
                positionGroup.removeAll()
                positionGroup.addObjects([
                createMarker(position, "Me", "",false,"red")
                ])
                map.current.addObject(positionGroup)
            }
            if (showRoute && (JSON.stringify(route) !== JSON.stringify({}))) plotRoute()
            if (showZones) plotZones()
            if (followPosition===true){
                map.current.getViewModel().setLookAtData({ bounds:positionGroup.getBoundingBox(),zoom: fixedZoom > 0 ? fixedZoom : currentSettings.map.zoom.value });                
            } else{
                //group.addObjects([positionGroup,zonesGroup,routeGroup])
                //map.current.getViewModel().setLookAtData({ bounds:group.getBoundingBox()});          
                map.current.getViewModel().setLookAtData({bounds:zonesGroup.getBoundingBox()})
            }

        } 
    },[position,map.current,showPos,showRoute,showZones,followPosition,route])

    useEffect(() => {
        // Check if the map object has already been created
        if (!map.current) {
            // Create a platform object with the API key and useCIT option
            platform.current = new H.service.Platform({
                apiKey
            });
            // Obtain the default map types from the platform object:
            const defaultLayers = platform.current.createDefaultLayers({
                pois: true
            });
            // Create a new map instance with the Tile layer, center and zoom level
            // Instantiate (and display) a map:
            const newMap = new H.Map(
                mapRef.current,
                defaultLayers.vector.normal.map, {
                    zoom: currentSettings.map.zoom.value,
                    center: {
                        lat: position.lat,
                        lng: position.lon,
                    },
                }
            );
            if (noUI!==true){
            const ui = H.ui.UI.createDefault(newMap, defaultLayers);
            }
            // Add panning and zooming behavior to the map
            const behavior = new H.mapevents.Behavior(
                new H.mapevents.MapEvents(newMap)
            );
            
            
            if (draggable == true) addDraggableMarker(newMap, behavior);
            //newMap.addObject(routeGroup)
            //positionGroup.addObjects([
            //    createMarker(position, "Me", "",false,"red")
            //])
            //newMap.addObject(positionGroup)

            // Set the map object to the reference
            map.current = newMap;
            
        }
    }, [apiKey,map,draggable]);

    const createMarker=(/*map,*/pos,label,settingsPath,draggable=true,color="blue")=>{
        let icon = new H.map.Icon(svgMarkup.replace("$COLOR", color).replace("$TEXT", label));
        var marker = new H.map.Marker({lat:pos.lat, lng:pos.lon}, {
          // mark the object as volatile for the smooth dragging
          volatility: true,
          icon: icon,
          data: settingsPath,
        });
        // Ensure that the marker can receive drag events
        marker.draggable = draggable;
        //map.addObject(marker);
        return marker
      }

    const addCommonMarkers=(map)=>{
   
      //createMarker(map, Geohash.decode(getSimData("simStart")), "A","location.simStart.value")
      //createMarker(map, Geohash.decode(getSimData("simEnd")), "B","location.simEnd.value")
      //createMarker(map, position, "X","", false,"red")
    }
    const addDraggableMarker=(map, behavior)=>{
        //addCommonMarkers(map)        
        // disable the default draggability of the underlying map
        // and calculate the offset between mouse and target's position
        // when starting to drag a marker object:
        map.addEventListener('dragstart', function(ev) {
          var target = ev.target,
              pointer = ev.currentPointer;
          if (target instanceof H.map.Marker) {
            var targetPosition = map.geoToScreen(target.getGeometry());
            target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
            behavior.disable();
          }
        }, false);

        // re-enable the default draggability of the underlying map
        // when dragging has completed
        map.addEventListener('dragend', function(ev) {
            var target = ev.target;
            if (target instanceof H.map.Marker) {
                if ("getGeometry" in target){
                    let newHash=Geohash.encode(target.getGeometry().lat, target.getGeometry().lng)
                    updateSettingValue(target.getData(),newHash)
                }
                behavior.enable();
            }

        }, false);

        // Listen to the drag event and move the position of the marker
        // as necessary
        map.addEventListener('drag', function(ev) {
            var target = ev.target,
                pointer = ev.currentPointer;
            if (target instanceof H.map.Marker) {
            target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
            }
        }, false);

    }
            
    // Return a div element to hold the map
    return <div style={{ width: "100%", height: Math.floor(height*0.6)+"px" }} ref={mapRef} />;
}
export default HereMap;