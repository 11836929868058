import SettingsParagraph from "../components/settings/SettingsParagraph";
import { useSettings } from "../context/settingsContext";

const VehiclePage = () => {
    const { currentSettings} = useSettings();
    return (
      <>
        <h2 className="tds-headline-01 tds-u-pb3">Setup the vehicle weight</h2>
        <div className="tds-u-flex-center">
        <p className="tds-body-01 tds-u-align-start">
            Normally the vehicle parameters would be automatically set, but for this demo we need to manually enter the values.
            </p>
        </div>
        <div>
            <SettingsParagraph key="vehicle" path="vehicle" settings={currentSettings} /> 
        </div>
      </>
    );
  };
  
  export default VehiclePage;
  