import { useState, useContext, createContext, useEffect } from "react";
import { useSettings } from "./settingsContext";
export type resizeContextT = {
  onMobile: boolean
  landscape: boolean
  width: number
  height: number
}
export const resizeContext = createContext<resizeContextT>({
  onMobile: false,
  landscape: true,
  width: 10,
  height: 5,
});

export function ResizeProvider(props) {
  const [landscape, setLandscape] = useState(window.innerWidth>window.innerHeight? true:false)
  const [width,setWidth] = useState(window.innerWidth)
  const [height,setHeight] = useState(window.innerHeight)
  const [onMobile, setOnMobile] = useState(
    window.innerWidth < 750 ? true : false
  );
  const [showSide, setShowSide] = useState(false);
  const [collapseSide, setCollapseSide] = useState(false);
  const {currentSettings} = useSettings()

  useEffect(() => {
    function handleResize() {
      console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
      setOnMobile(window.innerWidth < 750 ? true : false);
      setShowSide(window.innerWidth < 750 ? false:true);
      setCollapseSide(window.innerWidth < 1000 ? true:false);
      setLandscape(window.innerWidth>window.innerHeight? true:false);
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    if (currentSettings.general.actOnResize.value === true){
      window.addEventListener("resize", handleResize);
    }
    return () => { // Remove async from here
      const clear = async () => { // Create a new async function: clear
        window.removeEventListener("resize", handleResize);
      };
      clear() // Call the clear() function
    };
  },[currentSettings]);

  const contextValue = {
    onMobile,
    showSide,
    //setShowSide,
    landscape,
    //collapseSide,
    //setCollapseSide,
    width,
    height,
    //visibleSide,
  };

  return (
    <resizeContext.Provider value={contextValue}>
      {props.children}
    </resizeContext.Provider>
  );
}

export function useResizeContext() {
  return useContext(resizeContext);
}
