import { useEffect, useState } from "react";
import { useSettings } from "../context/settingsContext";
import { useHere } from "../context/hereContext";
import { usePosition } from "../context/positionContext";
//import { DisplayMapClass } from "../components/map/HereMap";
import  HereMap from "../components/map/NewHere";
import SettingsParameter from "../components/settings/SettingsParameter";

const SimulatorPage = () => {
    const [map,setMap] = useState("");
    const [route,setRoute] = useState([]);
    const {getMap,getRoute} = useHere();
    const {position}= usePosition();
    const {currentSettings,updateSettingValue} = useSettings();

    const fetchData = async() => {
      let mapdata = await getMap(position["lat"],position["lon"],currentSettings.map.zoom.value);
      setMap(mapdata);
    }
    useEffect(()=>{
      //fetchData()
    },[position])

    const fetchRoute = async (src:any,dest:any) =>{
      let data = await getRoute(src,dest);
      // Check if there is at least one route in the response
      const route = data.routes?.[0];
      if (!route) {
         throw new Error('No routes found');
      }
      // Extract departure, arrival, and polyline details from the first route's section
      const section = route.sections[0];
      const departure = section.departure.place.location;
      const arrival = section.arrival.place.location;
      const polyline = section.polyline;

      // Return an object containing departure, arrival, and polyline information
      return { departure, arrival, polyline };
    }

    useEffect(()=>{
      if (route.length>0){
        console.log(`New route: ${route}`);
        let dest = {lat:route[0][0],lon:route[0][1]};
        let src = {lat:route[1][0],lon:route[1][1]};
        fetchRoute(src,dest);
      }
    },[route])

    return (
      <>
        <h2 className="tds-headline-01 tds-u-pb3">Simulator</h2>
        <div className="tds-u-w-100">
        <p className="tds-body-01 tds-u-align-start tds-u-w-100">Change start and stop for the simulator by dragging the A and B markers. If markers jump while working, increase setting of simulator update</p>
        <SettingsParameter name="location" path="location.simSampleTime"/> 
        <HereMap showPos={ false } draggable={ true } showRoute={true} showZones={true} />
        </div>
        {/*<img src={map} alt="icons" />*
        <DisplayMapClass mypos={[position["lat"], position["lon"]]} pointselecter={setRoute} srcpos={currentSettings.location.simStart.value} destpos={currentSettings.location.simEnd.value} zoom={currentSettings.map.zoom.value}/>
          */}
        
      </>
    );
  };
  
export default SimulatorPage;
  