import { useState, useContext, createContext, useEffect } from "react";
import {jwtDecode} from "jwt-decode";
import { Authenticator} from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession} from 'aws-amplify/auth';
import { getCurrentUser } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
export type UserContextT = {
  handleLogout:() => void
  getExpirationTime: ()=> number
  accessToken: string
  isPartOfGroup: (c:string)=> boolean
  getUserName: ()=> string
  validLogin: boolean
}

export const UserContext = createContext<UserContextT>(
  {
    handleLogout: ()=>{},
    getExpirationTime: ()=>1,
    accessToken: "abc",
    isPartOfGroup: ()=> false,
    getUserName: ()=> "none",
    validLogin: false,
  }
);

export function UserProvider(props) {
  const [accessToken, setAccessToken] = useState("a");
  const [idToken, setIdToken] = useState("i");
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [validLogin, setValidLogin]=useState(false)
  const { route } = useAuthenticator(context => [context.route]);

  const currentAuthenticatedUser = async()=>{
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      console.log(`The username: ${username}`);
      console.log(`The userId: ${userId}`);
      console.log(`The signInDetails: ${signInDetails}`);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(()=>{
    try {
      const decoded = jwtDecode(accessToken);
      if (decoded["exp"]!>Math.floor(Date.now() / 1000)){
        setValidLogin(true)
      } else{
      setValidLogin(false)
      }
    } catch (e) {
      setValidLogin(false)
    }
  },[ accessToken ])

  const currentSession= async () => {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {accessToken: "a", idToken: "i"};
      console.log("Updating tokens")
      setAccessToken(accessToken.toString());
      setIdToken(idToken!.toString());
    } catch (err) {
      console.log(err);
    }
  }

  const getExpirationTime = () =>{
    try {
      const decoded = jwtDecode(idToken);
      return decoded.exp! * 1000
    } catch(e){
      return 0
    }
  }

  const getUserName = ()=>{
    try {
      const decoded = jwtDecode(idToken);
      return decoded!["email"];
    } catch (e) {
      return null;
    }
  }

  const isPartOfGroup = (groupName:string) =>{
    try {
     var decoded = jwtDecode(accessToken);
     if (decoded["cognito:groups"].includes(groupName)){
       return true;
     }else{
       return false;
     }
    } catch (e){
      return false;
    }
  }

  useEffect(() => {
    console.log("Use Effect from userContext");
    currentSession()
    currentAuthenticatedUser();
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event){
        case "signedIn":
          console.log("Authenticate user:", "Hub sign in", payload.data.username);
          currentSession();
          currentAuthenticatedUser();
          break;
        case 'signedOut':
          console.log('user have been signedOut successfully.');
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
      }
    });
  }, [ ]);

  const handleLogout=async() => {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const contextValue = {
    handleLogout,
    getExpirationTime,
    accessToken,
    isPartOfGroup,
    getUserName,
    validLogin,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {route === 'authenticated' ? props.children : <Authenticator />}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
