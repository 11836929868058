import { useState, useContext, useEffect,useRef, createContext} from "react";
import Geohash from "../tools/Geohash";
import { useSettings } from "./settingsContext";
import { useWebSocketContext } from "./websocketContext";
//export const PositionContext = createContext(null);
export type PositionContextT = {
  position: {},
  simState: string,
  updateSimList: (p:any)=>void,
  resetSim: ()=>void,
}
export const PositionContext = createContext<PositionContextT>({
  position: {},
  simState: "Init",
  updateSimList: ()=>{},
  resetSim: ()=>{},
});

export function PositionProvider(props:any) {
  const {currentSettings} = useSettings();
  const startPosition = Geohash.decode(currentSettings.location.simStart.value)
  const [gpsWatcher, setGpsWatcher] = useState(0);
  const simPositionList = useRef([[startPosition.lat,startPosition.lon,currentSettings.location.simSpeed.value/3.6]])
  const simIdx = useRef(0);
  const [simState,setSimState] = useState("Init")
  const [simIndex,setSimIndex] = useState(0)
  const { sendMessage, connectionStatus,} = useWebSocketContext();
  const simulatorMessage = simPositionList.current;
  //the global position, fed by gps sensor or simulator
  const [position, setGlobalPosition] = useState({
      lat: simPositionList.current[0][0],
      lon: simPositionList.current[0][1],
      timestamp: 0,
      accuracy: 999,
      heading: simPositionList.current[0][2],
      speed: currentSettings.location.simSpeed.value/3.6,
      geohash: Geohash.encode(simPositionList.current[0][0],simPositionList.current[0][1],9),
      source: "INIT"
    });
  //a ref to the global position, that we can use in useEffects without cause rerender
  const positionValue = useRef(position);
  useEffect(()=>{
    setSimIndex(simIdx.current)
  },[simIdx.current])

  useEffect(()=>{
    //Update the position ref when new position available
    //we don't want to send a new message every time the position is update,
    //so we store it in a ref
    positionValue.current = position
  },[position])

  const getSimulatedPosition = () => {
    //walk trough the list of points and update the global position
    //start by using fixed value, but we use api later on
    const lat = simPositionList.current[simIdx.current][0];
    const lon = simPositionList.current[simIdx.current][1];
    setGlobalPosition({
      lat: lat,
      lon: lon,
      timestamp: Date.now(),
      accuracy: 0.1,
      heading: simPositionList.current[simIdx.current][2],
      speed: currentSettings.location.simSpeed.value/3.6,
      geohash: Geohash.encode(lat, lon),
      source: "SIM"
    });
    console.log("Position: SIM ", lat, ",", lon, " geohash:", Geohash.encode(lat, lon));
    simIdx.current++
    setSimState("Running")
    if (simIdx.current>=simPositionList.current.length){
      simIdx.current = 0
    }
  };

  const newGPSPosition = (position) =>{
    //trigger on new GPS position from the gps chip. update global position
    console.log("Position: HW ", position.coords.latitude, ",", position.coords.longitude, " geohash:", Geohash.encode(position.coords.latitude, position.coords.longitude));
    setGlobalPosition({
      lat: position.coords.latitude,
      lon: position.coords.longitude,
      timestamp: position.timestamp,
      accuracy:
        position.coords.accuracy === null
          ? 999
          : position.coords.accuracy,
      heading:
        position.coords.heading === null ? -100 : position.coords.heading,
      speed:
        position.coords.speed === null ? -100 : position.coords.speed,
      geohash: Geohash.encode(
        position.coords.latitude,
        position.coords.longitude
      ),
      source: 'HW'
    });
  }

  const getGpsPosition = async () => {
    //instantiate the GPS watcher on the chip
    if (navigator.geolocation) {
      const id = navigator.geolocation.watchPosition(
        newGPSPosition,
        (err) => {
          console.warn(`ERROR(${err.code}): ${err.message}`);
        },
        {
          enableHighAccuracy: true,
          timeout: 2000,
          maximumAge: 60000,
        }
      );
      setGpsWatcher(id);
    }
  };

  useEffect(()=>{
    //handle change in useSimulator connected to the GPS chip
    if (currentSettings.location.useSimulator.value === false) {
      getGpsPosition();
    } else{
      console.log('Stopping GPS position watcher');
      if (gpsWatcher !== 0){
        navigator.geolocation.clearWatch(gpsWatcher);
        setGpsWatcher(0);
      }
    }
  },[currentSettings.location.useSimulator.value])

  useEffect(() => {
    //handle change in useSImulator connected to simulator
    if (currentSettings.location.useSimulator.value === true ) {
      const timerId = setInterval(() => {
        getSimulatedPosition();
      }, currentSettings.location.simSampleTime.value * 1000);
      return () => clearInterval(timerId);
    }
  },[
    currentSettings.location.simSampleTime.value,
    currentSettings.location.useSimulator.value,
  ]);

  useEffect(()=>{
    //handle sending of position message to backend
    console.log("Restarting position sending");
    if (currentSettings.connection.sendDataViaWS.value){
      const timerId2 = setInterval(()=>{
          console.log("Sending position message to backend " +JSON.stringify(positionValue.current) );
          sendMessage(`{"action":"position", "message":${JSON.stringify(positionValue.current)}}`)
        },currentSettings.connection.sendInterval.value*1000);
    return () => clearInterval(timerId2);
    }
  },[currentSettings.connection.sendInterval.value,currentSettings.connection.sendDataViaWS.value, sendMessage]);

  useEffect(()=>{
    //update the sim list if we get new incoming data
    console.log("Updating sim data")
    if (simulatorMessage !== null){      
      simPositionList.current = simulatorMessage;
      resetSim()
    }
  },[simulatorMessage]);

  const updateSimList=(newList:any) => {
    simPositionList.current = newList;
    resetSim()
  }
  useEffect(()=>{
    if (currentSettings.location.useSimulator.value===true){
      simIdx.current=0;
      let newPos = Geohash.decode(currentSettings.location.simStart.value)
      simPositionList.current = [[newPos.lat,newPos.lon,0]];
    }
  },[currentSettings.location.simStart.value,currentSettings.location.useSimulator.value])

  useEffect(()=>{
    //ask for a simulator list if the settings change, or if we don't have one yet
    if ( currentSettings.location.useSimulator.value===true && currentSettings.location.useRoute.value===true ){
      console.log("Asking for new simulator route")
      sendMessage(
        `{"action":"simulator","START":"${currentSettings.location.simStart.value}",
        "END":"${currentSettings.location.simEnd.value}",
        "EPSG":"${currentSettings.location.simEpsg.value}",
        "SPEED":"${currentSettings.location.simSpeed.value}",
        "SECONDS_PER_SAMPLE":"${currentSettings.location.simSampleTime.value}"
      }`
      );
    }
  },[currentSettings.location.useRoute.value,currentSettings.location.useSimulator.value])

  const resetSim = () =>{
    setSimState("Reset")
    simIdx.current = 0;
  }
  const contextValue = {
    position,
    simState,
    updateSimList,
    resetSim
  };

  return (
    <PositionContext.Provider value={contextValue}>
      {props.children}
    </PositionContext.Provider>
  );
}

export function usePosition() {
  return useContext(PositionContext);
}
