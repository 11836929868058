import { TdsLink } from "@scania/tegel-react";
import Logo from "../assets/logo.svg";
import "./HomePage.css";

const HomePage = () => {
  return (
    <>
      <h1 className="tds-headline-01 tds-u-pb3">Welcome to Smart Urban Traffic Zones 3</h1>
      <h3 className="tds-u-textalign-start">Project</h3>
      <p className="tds-u-textalign-start">This is a demonstration page for the project, more info can be found on the <TdsLink><a href="https://closer.lindholmen.se/projekt/smarta-urbana-trafikzoner" target="_blank">Closer project site</a></TdsLink></p>
      <p className="tds-u-textalign-start">Scania uses this page to demonstrate the concept of making permits available in a digital way to the driver and the transport company. <br /><br />
      The <b>Permit</b> and <b>Dashboard</b> tabs show concepts on how it could look in the vehicle, but are not to be seen as final implementation. 
      <br/>
      Even if it is common to put the driver into focus when talking about digitalization of permits, we consider the driving forces for this to be in priority order:
      <ol>
        <li>City</li>
          <ul>
            <li>Create a more sustainable transport system by easier/more flexible usage of permits (increase safety and efficiency)</li>
            <li>Improve follow-up and automatic compliance to the permits</li>
            <li>Facilitate administration</li>
          </ul>
        <li>Transport company</li>
          <ul>
            <li>Improve transport efficiency through more extensive use of an easier permit process</li>
            <li>Simplify application and administration</li>
            <li>Improved compliance to permit</li>
          </ul>
        <li>Vehicle manufacturer</li>
          <ul>
            <li>Offer customer more functionality</li>
            <li>Paves the way for more automation</li>
          </ul>
        <li>Driver</li>
          <ul>
            <li>Improved work environment</li>
              <ul>
                <li>Easier administration</li>
                <li>Automatic access to the right permit info at the right time</li>
                <li>Better compliance to the permit</li>
              </ul>
          </ul>
      </ol>
      
      </p>
      <h3 className="tds-u-textalign-start">Permits</h3>
      The permits used in the project are test permits issued by the City of Stockholm for the transporter M Logistics. Technolution converts these into datex2 format and distributes them to Scania.
      <h3 className="tds-u-textalign-start">Instructions</h3>
      <h4 className="tds-u-textalign-start">Device</h4>
      <p className="tds-u-textalign-start">This page can be used on most web browsers on any computer or mobile device. 
        <br/> It reacts on screen size, but it might feel a bit small on small mobile phone screens. It is intendent to be used on an Ipad</p>
      <p className="tds-u-textalign-start">If you want to use the your <b>live</b> position, your device needs to have a GPS chip, like on mobile phone or most high-end tablets.</p>
      <p className="tds-u-textalign-start">Without position chip, you can use the simulator to create a route between two points, and your position will be moving along the route and restart at the end</p>
      <h4 className="tds-u-textalign-start">Permits</h4>
      <p className="tds-u-textalign-start">On the permits tab, all the available permits are shown with their information. Pick the one to be activated. Currently only one permit is active at the time for easier troubleshooting.<br/>
      The idea with this tab is to replace the paper permit and show all information in one place, so it can be used for planning or to show to a controlling instance like the police.
      </p>
      <h4 className="tds-u-textalign-start">Dashboard</h4>
      <p className="tds-u-textalign-start">Activate dashboard to show how it could look in the vehicle dashboard while driving.<br/>
      It uses the device position to update the dashboard with relevant permit information.
      The intention is not to show all available information to the driver, but only things that matter right now.
      </p>
      <h4 className="tds-u-textalign-start">Location</h4>
      <p className="tds-u-textalign-start">Here you can see the selected route and your current position</p>
      <h4 className="tds-u-textalign-start">Setup</h4>
      <p className="tds-u-textalign-start">Using the tabs <b>Vehicle setup</b>, <b>Simulate route</b>, and <b>Time & date</b> it is possible to emulate different conditions</p>
      <h4 className="tds-u-textalign-start">Settings</h4>
      <p className="tds-u-textalign-start">Here you can change some settings, it is not always fully functional and mainly intendent for our own development purposes</p>
    </>
  );
};

export default HomePage;
