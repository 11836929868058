import { TdsAccordionItem, TdsBodyCell, TdsHeaderCell, TdsTable, TdsTableBody, TdsTableBodyRow, TdsTableHeader, TdsTextarea, TdsTextField } from "@scania/tegel-react";
import { access, getAllPaths } from "../../tools/Nested";
import { usePermit } from "../../context/permitContext";
import { createElement, useEffect, useState } from "react";
import {useHere} from "../../context/hereContext";
import { get } from "http";

const TrafficRegulations=({index, data})=>{
    const {permitFiles,activePermit,getActivePermitData} = usePermit()
    const {getZoneOnMap} = useHere()
    const [map,setMap] = useState("");
    //let startTime = getAllPaths(permitFiles[activePermit].fileData, 'overallStartTime');
    let endTime = getAllPaths(permitFiles[activePermit].fileData, 'overallEndTime');

    const returnTimes=(startTimes,endTimes,data): React.ReactNode=>{
        let arr: JSX.Element[] = [];
        startTimes.forEach((o,i)=>{
            arr.push(<li>{access(o,data)} - {access(endTimes[i], data)}</li>)                   
        })                            
        return (<ul>{arr}</ul>)
    }
    const recurringTimePeriodOfDay=(data2)=>{
        let startTimes = getAllPaths(data2, 'startTimeOfPeriod')
        let endTimes = getAllPaths(data2, 'endTimeOfPeriod') 
        return(
            getAllPaths(data2,'period').length>0 ?
            <TdsTableBodyRow>
                <TdsBodyCell>
                    Daily
                </TdsBodyCell>
                <TdsBodyCell>
                {access(getAllPaths(data2,'negate')[0],data2)===true?
                "Not active between"
                :"Active between"
                }    
                </TdsBodyCell>   
                <TdsBodyCell>
                {returnTimes(startTimes,endTimes,data2)}

                </TdsBodyCell>   
            </TdsTableBodyRow>
            :null

        )
    }

    const speedLimit = (data2) =>{
        return(
            getAllPaths(data2,'numericValue').length>0 ?
            <TdsTableBodyRow>
                <TdsBodyCell>
                    Speed Limit
                </TdsBodyCell>
                <TdsBodyCell>
                    {access(getAllPaths(data2,'numericValue')[0],data2)}
                </TdsBodyCell>   
                <TdsBodyCell>
                    {access(getAllPaths(data2,'unitOfMeasure')[0],data2)} 
                </TdsBodyCell>   
            </TdsTableBodyRow>
            :null

        )
    }

    const weightLimit = (data2)=>{
        return(
            getAllPaths(data2,'numericValue').length>0 ?
            <TdsTableBodyRow>
                <TdsBodyCell>Weight limit</TdsBodyCell>
                <TdsBodyCell>
                    {access(getAllPaths(data2,'numericValue')[0],data2)}
                </TdsBodyCell>   
                <TdsBodyCell>
                    {access(getAllPaths(data2,'unitOfMeasure')[0],data2)} 
                </TdsBodyCell>   
            </TdsTableBodyRow>
            :null

        )
    }
    const driverAdvice = (data2)=>{
        return(
            getAllPaths(data2,'value').length>0 ?
            <TdsTableBodyRow>
                <TdsBodyCell>Driver advice</TdsBodyCell>
                <TdsBodyCell>
                    {access(getAllPaths(data2,'value')[0],data2)}
                </TdsBodyCell>   
                <TdsBodyCell>
                </TdsBodyCell>   
            </TdsTableBodyRow>
            :null

        )
    }

    useEffect(()=>{ ( async () => { 
        let mapData = access(getAllPaths(data, 'gmlMultiPolygon')[0],data)
        let newMap = await getZoneOnMap(mapData)
        setMap(newMap)
    })() 
    },[])

    return (
                <TdsAccordionItem header={data.controlledZoneControlledZoneRegulation.permitInformation[0].description.values[0].value}>
                        <TdsTable  vertical-dividers="false" compact-design="true" responsive={false}>
                        <TdsTableHeader>
                        <TdsHeaderCell>
                        Subject
                        </TdsHeaderCell>
                        <TdsHeaderCell>
                        Info
                        </TdsHeaderCell>
                        <TdsHeaderCell>
                        Extra
                        </TdsHeaderCell>
                        </TdsTableHeader>
                        <TdsTableBody>
                        <TdsTableBodyRow>
                            <TdsBodyCell>
                                Overall validity
                            </TdsBodyCell>
                            <TdsBodyCell>
                                {access(getAllPaths(data, 'overallStartTime')[0],data)}
                            </TdsBodyCell>   
                            <TdsBodyCell>
                                {access(getAllPaths(data, 'overallEndTime')[0],data)}
                            </TdsBodyCell>   
                        </TdsTableBodyRow>
                       {recurringTimePeriodOfDay(access(getAllPaths(data, 'controlledZonePeriodCondition')[0],data))}
                       {speedLimit(access(getAllPaths(data, 'trafficRegulationSpeedLimit')[0], data))}
                       {weightLimit(access(getAllPaths(data, 'trafficRegulationGrossWeightLimit')[0], data))}
                       {driverAdvice(access(getAllPaths(data, 'trafficRegulationDriverAdvice')[0], data))}
                        </TdsTableBody>
                    </TdsTable>
                    <img src={map} alt="icons" />
                </TdsAccordionItem>        
    )
}

export default TrafficRegulations;