
const AboutPage = () => {
    return (
        <>
            <h1 className="tds-headline-01 tds-u-mb-2">General Info</h1>
            <p className="tds-body-01">
            <h2>Permits</h2>
                These are all test permits from Stockholm City send to Scania through Technolution in the context of the Smart Urban Traffic Zones project.
            <h2>Privacy</h2>
                While using the app, it might send position data to the backend to be able to match the current position of the device with the permits.
                This position data is stored during maximum 1 week for debugging purposes, only accessible for the people at Scania R&D working within the project.
                The data is automatically deleted after the retention time.
            </p>
        </>
    );
};

export default AboutPage;
